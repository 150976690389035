import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { isIOS13 } from 'react-device-detect';
import Header from './components/Header';
import SelectStory from './pages/SelectStory';
import SelectScene from './pages/SelectScene';
import UploadPictures from './pages/UploadPictures';
import ScreenResult from './pages/Result';
import i18n from 'i18next';
import './i18n/config';
import { Language } from './bdreams/FaceSwap';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsAndConditions from './pages/TermsAndConditions';
import clsx from 'clsx';
import styles from './components/Header/styles.module.scss';
import TermsLinks from './components/ui/TermsLinks';
import { loadAPIVideos } from './helpers/utils';
import triggerDataLayer from './helpers/dataLayerTrigger';
import { useTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery'

function App() {
    const canUseFestiveMode = new Date() < new Date(process.env.REACT_APP_FESTIVE_MODE_AVAILABLE_UNTIL as string) &&
      localStorage.getItem('user-selected-language') === 'en';

    const location = useLocation();
    const [isAppReady, setIsAppReady] = useState(false);
    const [isClicked, setIsClicked] = useState(true);
    const [videoLoaded, setVideoLoaded] = useState(0);
    const [festiveModeOn, setFestiveModeOn] = useState(
      canUseFestiveMode ?
        !!localStorage?.getItem('festiveMode') ? localStorage?.getItem('festiveMode') === 'true' : true
        : false
    );
    const isTablet = useMediaQuery('(max-width:768px)');
    const { t } = useTranslation();
    useEffect(() => {
        document.addEventListener('keydown', handleKeyboardLastUsed);
        document.addEventListener('click', handleMouseLastUsed);
        document.addEventListener('click', handleCookieConsentAction);
        return () => {
            document.removeEventListener('keydown', handleKeyboardLastUsed);
            document.removeEventListener('click', handleMouseLastUsed);
            document.removeEventListener('click', handleCookieConsentAction);
        };
    }, []);

    useEffect(() => {
        if (isAppReady) {
            localStorage.setItem('festiveMode', festiveModeOn.toString());
        }
    }, [festiveModeOn, isAppReady])

    const handleCookieConsentAction = (event: MouseEvent) => {
        const cookieActionIds = [
            'cookie-accept-all',
            'rejectAll',
            'save-selection',
        ];
        if (cookieActionIds.includes((event.target as HTMLElement).id)) {
            triggerDataLayer('banner_update');
        }
    };

    const handleKeyboardLastUsed = () => {
        setIsClicked(false);
    };
    const handleMouseLastUsed = () => {
        setIsClicked(true);
    };

    useEffect(() => {
        const initializeApp = async () => {
            const acceptedRoutes = ['/termsandconditions', '/privacypolicy'];
            if (
                !acceptedRoutes.includes(window.location.pathname) &&
                !/scene\d+\/result\/.+/.test(window.location.pathname) && // don't redirect on a result page
                window.history.length <= 2 &&
                window.location.pathname !== '/' &&
                window.location.pathname !== ''
            ) {
                window.location.href = '/';
                return;
            }
            await loadAPIVideos(i18n.language as Language, t, { festiveModeOn, });
            setVideoLoaded(videoLoaded + 1);
            setIsAppReady(true);
        };
        initializeApp();
    }, []);

    useEffect(() => {
        const refreshVideos = async () => {
            await loadAPIVideos(i18n.language as Language, t, { festiveModeOn, });
            setVideoLoaded(videoLoaded + 1);
        }
        refreshVideos();
    }, [festiveModeOn]);

    if (!isAppReady) {
        return <main className="main" />; // Show loading message or spinner
    }

    return (
        <main
            className={clsx(
                'main',
                {
                    createPage: location.pathname === '/create',
                },
                {
                    [styles.appClicked]: isClicked,
                },
                {
                  isIOS13
                },
                { festiveModeOn: festiveModeOn && !isTablet && canUseFestiveMode && ['/', '/selectyourscene'].includes(location.pathname) },
                { festiveModeTablet: festiveModeOn && isTablet && canUseFestiveMode && ['/'].includes(location.pathname) }
            )}
        >
            <Header festiveModeOn={festiveModeOn} />
            <Routes>
                <Route path="/selectyourscene" element={
                    <SelectScene
                      festiveModeOn={festiveModeOn}
                      setFestiveModeOn={setFestiveModeOn}
                      canUseFestiveMode={canUseFestiveMode}
                    />
                } />
                <Route path="/privacypolicy" element={<PrivacyPolicy />} />
                <Route
                    path="/termsandconditions"
                    element={<TermsAndConditions />}
                />
                <Route
                    path="/:sceneNumber/result/:jobId"
                    element={<ScreenResult
                        festiveModeOn={festiveModeOn && canUseFestiveMode}
                    />}
                />
                <Route
                    path="/:sceneNumber/create"
                    element={<UploadPictures
                        festiveModeOn={festiveModeOn && canUseFestiveMode}
                    />}
                />
                <Route path="/" element={
                    <SelectStory
                        festiveModeOn={festiveModeOn}
                        setFestiveModeOn={setFestiveModeOn}
                        canUseFestiveMode={canUseFestiveMode}
                    />
                } />
            </Routes>
            <TermsLinks festiveModeOn={
                (festiveModeOn && !isTablet && canUseFestiveMode && ['/', '/selectyourscene'].includes(location.pathname))
            } />
            <div id="cookie-consent-container" />
        </main>
    );
}

export default App;
