import React from 'react';
import { Link as ReactLink } from 'react-router-dom';
import clsx from 'clsx';
import styles from './styles.module.scss';

interface Props {
    to: string;
    variant?: 'contained' | 'outlined';
    color?: 'burgundy' | 'white' | 'green';
    className?: string;
    download?: boolean;
    target?: string;
    state?: object;
    children: React.ReactNode;
    tabIndex?: number;
    title?: string;
    id?: string;
    onClick?: () => void;
}

const Link = ({
    to,
    variant,
    color,
    state,
    className,
    download,
    target,
    children,
    tabIndex,
    title,
    id,
    onClick
}: Props) => {
    return (
        <ReactLink
            to={to}
            className={clsx(styles.link, className, {
                [styles.outlined]: variant === 'outlined',
                [styles.white]: color === 'white',
                [styles.green]: color === 'green',
            })}
            download={download}
            state={state}
            target={target}
            tabIndex={tabIndex}
            title={title}
            id={id}
            onClick={onClick}
        >
            {children}
        </ReactLink>
    );
};
export default Link;
