import React, { useEffect, useMemo, useRef, useState } from 'react'
import clsx from 'clsx'
import { Swiper, SwiperSlide } from 'swiper/react'
import Camera from 'react-html5-camera-photo'
import 'react-html5-camera-photo/build/css/index.css'
import Button from '../../components/ui/Button'
import UploadGuide from '../../components/UploadGuide'
import useMediaQuery from '@mui/material/useMediaQuery'
import styles from './styles.module.scss'
import { useTranslation } from 'react-i18next'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI'
import { FaceImageLandmarks, FaceSwapVideo } from '../../bdreams/FaceSwap'
import AgreementsDialog from '../../components/AgreementsDialog'
import SlideActions from '../../components/SlideActions.tsx'
import AnalyzingPictureBox from '../../components/AnalyzingPictureBox.tsx'
import Lottie from 'react-lottie'
import lottieLoader from '../../assets/button-loader.json'
import { useCommon } from '../../state/common'
import Resizer from 'react-image-file-resizer'
import { FaceSwapItem, FaceSwapItemStatus } from '../../@types/face-swaps'
import arrowLeft from '../../assets/icons/arrow-left.svg'
import triggerDataLayer, { DataLayerEventPath } from '../../helpers/dataLayerTrigger'

const UploadPictures = ({
  festiveModeOn
                        }: {
    festiveModeOn: boolean;
}) => {
    const { t, i18n: { language } } = useTranslation();
    const isFrenchLanguage = language === 'fr';
    const isItalianLanguage = language === 'it';
    const isGermanLanguage = language === 'de';
    const isPortugueseLanguage = language === 'pt-BR';
    const isChineseLanguage = language === 'zh-CN';

    const navigate = useNavigate();
    const { sceneNumber: paramsSceneNumber } = useParams();
    let { state } = useLocation();
    const isTablet = useMediaQuery('(max-width:768px)');
    const [isGuideOpened, setIsGuideOpened] = useState<boolean>(false);
    const [targetElement, setTargetElement] = useState<any>(null);
    const [lastUsedTargetElementType, setLastUsedTargetElementType] = useState<string>('');
    const [isNoticeDialogOpened, setIsNoticeDialogOpened] =
        useState<boolean>(false);
    const [isTermsDialogOpened, setIsTermsDialogOpened] =
        useState<boolean>(false);
    const [picture, setPicture] = useState<string | null>(null);
    const [pictureSkinType, setPictureSkinType] = useState<number>(0);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isPictureApproved, setIsPictureApproved] = useState<boolean | null>(
        null
    );
    const [swaps, setSwaps] = useState<FaceSwapItem[]>();
    const [video, setVideo] = useState<FaceSwapVideo>();
    const [changeSoruce, setChangeSource] = useState<FaceSwapItem>();
    const [agreements, setAgreements] = useState({
        privacy: localStorage.getItem('qatar-privacy') || null,
        terms: localStorage.getItem('qatar-terms') || null,
    });
    const [isRequestSended, setIsRequestSended] = useState<boolean>(false);
    const [uploadedImage, setUploadedImage] = useState<FaceImageLandmarks>();
    const lottieAnimationRef = useRef<any>(null);
    const { isCameraOn, setIsCamerOn } = useCommon();
    const paramsVideoId = useMemo(() => {
        const sceneNumber = parseInt(
            paramsSceneNumber?.replace('scene', '') || ''
        );
        if (sceneNumber) {
            return FaceSwapAPI.videos[sceneNumber - 1]?.id;
        }
    }, [paramsSceneNumber]);

    useEffect(() => {
        document.title = t('create_page_title');
    }, [t]);

    useEffect(() => {
        const swaps: FaceSwapItem[] = [];

        if (FaceSwapAPI.videos.length) {
            const videoState = state?.state?.videoId
                ? FaceSwapAPI.videoByID(state.state.videoId)
                : paramsVideoId
                ? FaceSwapAPI.videoByID(paramsVideoId)
                : null;
            if (videoState) {
                setVideo(videoState);
                const images = videoState.targets.slice();
                for (let idx = 0; idx < images.length; idx++) {
                    const image = images[idx];
                    swaps.push({
                        source: {
                            id: '',
                            url: image.url,
                        },
                        target: {
                            id: image.id,
                            url: image.url,
                            urlThumb: image.urlThumb,
                            urlThumbWide: image.urlThumbWide,
                            title: image.title,
                            description: image.description,
                        },
                        status: FaceSwapItemStatus.NO_IMAGE,
                    });
                }
            }
        }

        setSwaps(swaps);
    }, [state, paramsVideoId]);

    useEffect(() => {
        if (lottieAnimationRef?.current?.el) {
            lottieAnimationRef.current.el.tabIndex = -1;
        }
        // eslint-disable-next-line
    }, [lottieAnimationRef.current]);

    useEffect(() => {
        if (picture && !isCameraOn) {
            document
                .getElementsByClassName('main')[0]
                .classList.add('secondaryBg');
        } else {
            document
                .getElementsByClassName('main')[0]
                .classList.remove('secondaryBg');
        }
    }, [picture, isCameraOn]);

    const dataURLtoFile = (dataurl: any, filename: string) => {
        let arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[arr.length - 1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    };

    const handlePrivacyAgreement = (
        e: React.MouseEvent,
        type: string,
        item: FaceSwapItem | undefined
    ) => {
        setLastUsedTargetElementType(type);
        if (!agreements.privacy) {
            e.preventDefault();
            setIsNoticeDialogOpened(true);
            setTargetElement(e.target);
        } else {
            !isTablet && type === 'photo' && setIsCamerOn(true);
            setChangeSource(item);
        }
    };

    const resizeFile = (file: any) =>
        new Promise((resolve) => {
            Resizer.imageFileResizer(
                file,
                2048,
                2048,
                'jpeg',
                80,
                0,
                (uri) => {
                    resolve(uri);
                },
                'file',
                256,
                256
            );
        });

    const handleFileChange = async (event: any, type: 'upload' | 'camera') => {
        if (changeSoruce) {
            const file: any =
                event.target && event.target.files.length > 0
                    ? await resizeFile(event?.target?.files[0])
                    : dataURLtoFile(event, 'camera.png');

            setPicture(URL.createObjectURL(file));

            const sceneNumber = FaceSwapAPI?.videos?.findIndex(
                (apiVideo) => apiVideo.id === video?.id
            );

            try {
                setIsPictureApproved(null);
                setIsLoading(true);
                const image = await FaceSwapAPI.uploadImage(file);
                // let foundFace = image.landmarks.length > 0;
                // if (!foundFace) {
                //     throw new Error('No face found');
                // }

                triggerDataLayer('photo_validation_complete', {
                    photo_validation_status: 'success',
                    photo_source: type,
                    scene_number: `scene ${sceneNumber + 1}`,
                    scene_description: video?.title,
                    path: festiveModeOn ? DataLayerEventPath.FESTIVE : DataLayerEventPath.STANDARD,
                });

                setUploadedImage(image);
                setPictureSkinType(image.skinType);
                setIsLoading(false);
                setIsPictureApproved(true);
                // setTimeout(() => setPicture(null), 1000);
                setSwaps(swaps?.slice()); // redraw
                setIsCamerOn(false);
            } catch (error: Error | any) {
                // setMessage(error.message);
                if (error.message.toLowerCase() !== 'canceled') {
                    triggerDataLayer('photo_validation_complete', {
                        photo_validation_status: 'fail',
                        photo_source: type,
                        scene_number: `scene ${sceneNumber + 1}`,
                        scene_description: video?.title,
                        path: festiveModeOn ? DataLayerEventPath.FESTIVE : DataLayerEventPath.STANDARD,
                    });
                    changeSoruce.source.url = URL.createObjectURL(file);
                    changeSoruce.status = FaceSwapItemStatus.ERROR;
                    setIsLoading(false);
                    setIsPictureApproved(false);
                }
                setSwaps(swaps?.slice()); // redraw
            }
        }
    };

    const handleContinueUploadPicture = async() => {
        if (changeSoruce && uploadedImage) {
            if (pictureSkinType === 0) return;
            if (pictureSkinType !== uploadedImage.skinType) {
                try {
                    await FaceSwapAPI.updateImageSkinType(uploadedImage.id, pictureSkinType);
                } finally {
                    setPictureSkinType(0);
                }
            }

            changeSoruce.source.id = uploadedImage.id;
            changeSoruce.source.url = uploadedImage.url;
            changeSoruce.status = FaceSwapItemStatus.OK;
            setSwaps(swaps?.slice()); // redraw
        }
    };

    const handleCreateVideo = async () => {
        if (video === undefined || swaps === undefined) return;
        const videoId = video.id;
        const sourceIds: string[] = [];
        const targetIds: string[] = [];

        for (const swap of swaps) {
            if (
                swap.status === FaceSwapItemStatus.OK &&
                swap.source.id.length === 36
            ) {
                sourceIds.push(swap.source.id);
                targetIds.push(swap.target.id);
            }
        }
        try {
            const videoSceneNumber = FaceSwapAPI?.videos?.findIndex(
                (apiVideo) => apiVideo.id === video?.id
            );
            triggerDataLayer('create_video', {
                scene_number: `scene ${videoSceneNumber + 1}`,
                scene_description: video?.title,
                path: festiveModeOn ? DataLayerEventPath.FESTIVE : DataLayerEventPath.STANDARD,
            });
            setIsRequestSended(true);
            const jobId = await FaceSwapAPI.createJob(
                videoId,
                sourceIds,
                targetIds
            );

            navigate(`/${paramsSceneNumber}/result/${jobId}`);
        } catch (error: Error | any) {
            setIsRequestSended(false)
            // setMessage(error.message);
        }
    };

    const handleBackBtn = () => {
        if (isCameraOn) {
            setIsCamerOn(false);
            return;
        }
        if (picture) {
            FaceSwapAPI.abortRequest();
            setPicture(null);
            return;
        }
        navigate('/selectyourscene');
    };

    return (
        <div
            className={clsx(styles.wrap, 'wrap', {
                [styles.aligned]: swaps && swaps?.length < 2,
            })}
        >
            <button
                type="button"
                id="back-button"
                className={styles.backBtn}
                onClick={() => handleBackBtn()}
                title={t('title_go_back')}
            >
                <img src={arrowLeft} alt="" />
            </button>
            {picture && !isCameraOn ? (
                <AnalyzingPictureBox
                    isPictureApproved={isPictureApproved}
                    setPicture={setPicture}
                    setChangeSource={setChangeSource}
                    changeSoruce={changeSoruce}
                    handlePrivacyAgreement={handlePrivacyAgreement}
                    handleFileChange={handleFileChange}
                    setIsGuideOpened={setIsGuideOpened}
                    isGuideOpened={isGuideOpened}
                    isLoading={isLoading}
                    picture={picture}
                    pictureSkinType={pictureSkinType}
                    setPictureSkinType={setPictureSkinType}
                    handleContinueUploadPicture={handleContinueUploadPicture}
                    lastUsedTargetElementType={lastUsedTargetElementType}
                />
            ) : (
                <>
                    {isCameraOn ? (
                        <div className={styles.cameraScreenContainer}>
                            <div className={styles.cameraBox}>
                                <Camera
                                    isImageMirror
                                    idealResolution={{
                                        width: 567,
                                        height: 664,
                                    }}
                                    onCameraStart={() => {
                                        const divOuterCircle =
                                            document.getElementById(
                                                'outer-circle'
                                            );
                                        if (divOuterCircle) {
                                            divOuterCircle.tabIndex = 0;
                                            divOuterCircle.onkeydown = (e) => {
                                                if (e.key === 'Enter') {
                                                    divOuterCircle.click();
                                                }
                                            };
                                            divOuterCircle.title =
                                                t('take_photo');
                                        }
                                    }}
                                    onTakePhoto={(e) => {
                                        handleFileChange(e, 'camera');
                                        setIsCamerOn(false);
                                        // setPicture(dataUri);
                                    }}
                                    onCameraError={(error) => {
                                        console.log('error', error);
                                        const divOuterCircle =
                                            document.getElementById(
                                                'outer-circle'
                                            );
                                        if (divOuterCircle) {
                                            divOuterCircle.style.display =
                                                'none';
                                        }
                                        setTimeout(() => {
                                            const cameraErrorMessage =
                                                document.getElementById(
                                                    'display-error'
                                                );
                                            if (cameraErrorMessage) {
                                                cameraErrorMessage.style.setProperty(
                                                    '--before-content',
                                                    `'${t('camera_error')}'`
                                                );
                                                cameraErrorMessage.style.display =
                                                    'block';
                                            }
                                        }, 0);
                                    }}
                                />
                            </div>
                        </div>
                    ) : (
                        <>
                            <div className={styles.mobileBox}>
                                <Button
                                    type="button"
                                    id="create-video-button"
                                    color="white"
                                    disabled={
                                        !swaps?.some(
                                            (swap) =>
                                                swap?.source?.id &&
                                                swap?.status ===
                                                    FaceSwapItemStatus.OK
                                        ) || isRequestSended
                                    }
                                    onClick={(e: any) => {
                                        if (agreements.terms) {
                                            handleCreateVideo();
                                        } else {
                                            setIsTermsDialogOpened(true);
                                            setTargetElement(e.target);
                                        }
                                    }}
                                    title={t('title_create_video')}
                                    className={styles.uploadButton}
                                >
                                    {isRequestSended && (
                                        <div className={styles.loaderBox}>
                                            <Lottie
                                                ref={lottieAnimationRef}
                                                options={{
                                                    animationData: lottieLoader,
                                                }}
                                                height={64}
                                                width={64}
                                                isClickToPauseDisabled={true}
                                            />
                                        </div>
                                    )}
                                    {t('create_video')}
                                </Button>
                                <Button
                                    type="button"
                                    id="upload-guide-button"
                                    variant="outlined"
                                    className={styles.uploadButton}
                                    onClick={() => setIsGuideOpened(true)}
                                    title={t('title_upload_guide')}
                                >
                                    {t('upload_guide')}
                                </Button>
                                <UploadGuide
                                    open={isGuideOpened}
                                    handleClose={() => setIsGuideOpened(false)}
                                />
                            </div>

                            <Swiper
                                key={
                                    isTablet
                                        ? 'swiper-tablet'
                                        : 'swiper-desktop'
                                }
                                dir={document.body.dir}
                                spaceBetween={16}
                                slidesPerView={1.2}
                                allowTouchMove={isTablet ? true : false}
                                centeredSlides={true}
                                className={clsx(styles.slider)}
                            >
                                {(swaps || []).map((item, key) => (
                                    <SwiperSlide
                                        className={styles.slide}
                                        key={key}
                                    >
                                        {item.status === 'Ok' && (
                                            <div
                                                className={clsx(
                                                    'body1',
                                                    styles.greatPhotoMobileMsg
                                                )}
                                            >
                                                {t('great_photo')}
                                            </div>
                                        )}
                                        {item.status !== 'Ok' && (
                                            <div className={clsx(styles.info, {
                                                [styles.frenchInfo]: isFrenchLanguage
                                            })}>
                                                <h2 className={styles.name}>
                                                    {item.target.title}
                                                </h2>
                                                <p className={styles.subtitle}>
                                                    {item.target.description}
                                                </p>
                                            </div>
                                        )}

                                        <div
                                            className={clsx(styles.imgRow, {
                                                [styles.doubleImg]:
                                                    item.status === 'Ok',
                                            })}
                                        >
                                            {item.status === 'Ok' && (
                                                <div
                                                    className={clsx(
                                                        styles.imgBox,
                                                        styles.smallImgBox
                                                    )}
                                                    title={`${t(
                                                        'your_photo_for'
                                                    )} ${item.target.title}`}
                                                >
                                                    <img
                                                        src={item.source.url}
                                                        alt=""
                                                    />
                                                </div>
                                            )}
                                            <div
                                                className={clsx(styles.imgBox, {
                                                    [styles.smallImgBox]:
                                                        item.status === 'Ok',
                                                })}
                                                title={item.target.title}
                                            >
                                                <img
                                                    src={
                                                        isTablet ||
                                                        item.status === 'Ok'
                                                            ? item.target
                                                                  .urlThumb
                                                            : item.target
                                                                  .urlThumbWide
                                                    }
                                                    alt=""
                                                />
                                            </div>
                                        </div>

                                        <SlideActions
                                            inputId={`upload-img-${key}`}
                                            setChangeSource={setChangeSource}
                                            changeSoruce={item}
                                            handlePrivacyAgreement={
                                                handlePrivacyAgreement
                                            }
                                            handleFileChange={handleFileChange}
                                        />
                                    </SwiperSlide>
                                ))}
                            </Swiper>

                            <div
                                className={clsx(styles.box, {
                                    [styles.boxFrench]: isFrenchLanguage || isItalianLanguage,
                                    [styles.boxPortuguese]: isPortugueseLanguage,
                                    [styles.boxGerman]: isGermanLanguage,
                                    // [styles.aligned]:
                                    //     swaps && swaps?.length < 2,
                                })}
                            >
                                {swaps?.some(
                                    (swap) =>
                                        swap?.source?.id &&
                                        swap?.status === FaceSwapItemStatus.OK
                                ) ? (
                                    <>
                                        <p
                                            className={clsx(
                                                styles.greatPhoto,
                                                'h1',
                                                { [styles.greatPhotoChinese]: isChineseLanguage },
                                            )}
                                        >
                                            {t('great_photo')}
                                        </p>
                                        {swaps &&
                                            swaps.length > 1 &&
                                            swaps?.some(
                                                (swap) =>
                                                    !swap?.source.id ||
                                                    swap?.status !==
                                                        FaceSwapItemStatus.OK
                                            ) && (
                                                <>
                                                    <p
                                                        className={clsx(
                                                            styles.uploadYourPhoto,
                                                            'h1'
                                                        )}
                                                    >
                                                        {t(
                                                            'upload_pictures',
                                                            { count: 1 }
                                                        )}
                                                    </p>
                                                    <p className={clsx('body1', {
                                                      [styles.body1German]: isGermanLanguage,
                                                    })}>
                                                        {t(
                                                            'choose_which_character'
                                                        )}
                                                    </p>
                                                </>
                                            )}
                                    </>
                                ) : (
                                    <>
                                        <h1 className="h1">
                                            {t('upload_pictures', {
                                                count: swaps?.length,
                                            })}
                                        </h1>
                                        <p className={clsx('body1')}>
                                            {t('minimum_of_one_image', {
                                                count: swaps?.length,
                                            })}
                                        </p>
                                    </>
                                )}

                                <Button
                                    type="button"
                                    id="create-video-button"
                                    color="white"
                                    disabled={
                                        !swaps?.some(
                                            (swap) =>
                                                swap?.source?.id &&
                                                swap?.status ===
                                                    FaceSwapItemStatus.OK
                                        ) || isRequestSended
                                    }
                                    onClick={(e: any) => {
                                        if (agreements.terms) {
                                            handleCreateVideo();
                                        } else {
                                            setIsTermsDialogOpened(true);
                                            setTargetElement(e.target);
                                        }
                                    }}
                                    title={t('title_create_video')}
                                    className={styles.submitBtn}
                                >
                                    {isRequestSended && (
                                        <div className={styles.loaderBox}>
                                            <Lottie
                                                ref={lottieAnimationRef}
                                                options={{
                                                    animationData: lottieLoader,
                                                }}
                                                height={64}
                                                width={64}
                                                isClickToPauseDisabled={true}
                                            />
                                        </div>
                                    )}
                                    {t('create_video')}
                                </Button>
                                <Button
                                    type="button"
                                    id="upload-guide-button"
                                    variant="outlined"
                                    className={styles.uploadButton}
                                    onClick={() => setIsGuideOpened(true)}
                                    title={t('title_upload_guide')}
                                >
                                    {t('upload_guide')}
                                </Button>
                                <UploadGuide
                                    open={isGuideOpened}
                                    handleClose={() => setIsGuideOpened(false)}
                                />

                                {/* Privacy dialog */}
                                <AgreementsDialog
                                    open={
                                        !agreements.privacy &&
                                        isNoticeDialogOpened
                                    }
                                    type={'privacy'}
                                    title={t('pp_content_title')}
                                    text={t('legal_notice')}
                                    submitBtnText={t('agree_privacy_notice')}
                                    handleOpen={() => {
                                        setIsNoticeDialogOpened(true);
                                    }}
                                    handleClose={() => {
                                        setIsNoticeDialogOpened(false);
                                    }}
                                    handleSubmit={() => {
                                        localStorage.setItem(
                                            'qatar-privacy',
                                            'according'
                                        );
                                        setAgreements((prev) => ({
                                            ...prev,
                                            privacy: 'according',
                                        }));
                                        setTimeout(() => {
                                            targetElement?.dispatchEvent(
                                                new MouseEvent('click', {
                                                    bubbles: true,
                                                })
                                            );
                                        });
                                        setTargetElement(null);
                                    }}
                                />

                                {/* Terms dialog */}
                                <AgreementsDialog
                                    open={
                                        !agreements.terms && isTermsDialogOpened
                                    }
                                    type="terms"
                                    title={t('tc_content_title')}
                                    text={t('legal_notice')}
                                    submitBtnText={t('agree_terms_conditions')}
                                    handleOpen={() => {
                                        setIsTermsDialogOpened(true);
                                    }}
                                    handleClose={() => {
                                        setIsTermsDialogOpened(false);
                                    }}
                                    handleSubmit={() => {
                                        localStorage.setItem(
                                            'qatar-terms',
                                            'according'
                                        );
                                        setAgreements((prev) => ({
                                            ...prev,
                                            terms: 'according',
                                        }));
                                        setTimeout(() => {
                                            targetElement?.dispatchEvent(
                                                new MouseEvent('click', {
                                                    bubbles: true,
                                                })
                                            );
                                        });
                                        setTargetElement(null);
                                    }}
                                />
                            </div>
                        </>
                    )}
                </>
            )}
        </div>
    );
};
export default UploadPictures;
