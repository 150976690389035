import React, { useEffect, useState } from 'react'
import Dialog from '@mui/material/Dialog'
import {
  Box,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material'
import Button from '../ui/Button'
import closeIcon from '../../assets/icons/close-modal.svg'
import { useTranslation } from 'react-i18next'
import { FaceSwapAPI } from '../../bdreams/FaceSwapAPI'
import triggerDataLayer, { DataLayerEventPath } from '../../helpers/dataLayerTrigger'
import styles from '../AgreementsDialog/styles.module.scss'
import SwipeableDrawer from '@mui/material/SwipeableDrawer'
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

interface IProps {
  open: boolean;
  handleClose: () => void;
  jobId?: string;
  festiveModeOn: boolean;
}

const NotifyModal = (props: IProps) => {
  const { t, i18n: { language } } = useTranslation();
  const { executeRecaptcha } = useGoogleReCaptcha();

  const { open, handleClose, jobId } = props;
  const [value, setValue] = useState<string>('');
  const [emailError, setEmailError] = useState<boolean>(false);
  const [success, setSuccess] = useState<boolean | undefined>(undefined);
  const isTablet = useMediaQuery('(max-width:768px)');

  const isArabicLanguage = language === 'ar';

  useEffect(() => {
    if (open) {
      setValue('');
      setEmailError(false);
      setSuccess(undefined);
    }
  }, [open]);

  const handleSubmit = async(event: any) => {
    event.preventDefault();
    try {
      let userRecaptchaToken = '';
      if (executeRecaptcha) {
        userRecaptchaToken = await executeRecaptcha('notifyMe');
        if (!userRecaptchaToken) {
          alert(t('site_recaptcha_error'));
          return;
        }
      } else {
        alert(t('site_recaptcha_error'));
        return;
      }
      // check email input first
      if (!value || !value.match(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/)) {
        setEmailError(true);
        return;
      }
      if (jobId) {
        FaceSwapAPI.jobNotify(jobId, value, userRecaptchaToken)
          .then(() => {
            setSuccess(true);
            return FaceSwapAPI.jobStatus(jobId).then((data) => {
              const videoSceneNumber = FaceSwapAPI.videos.findIndex((video) => video.id === data.swapVideoId);
              const apiVideo = FaceSwapAPI.videoByID(data.swapVideoId);
              triggerDataLayer('email_submission', {
                'scene_number': `scene ${videoSceneNumber + 1}`,
                'scene_description': apiVideo?.title,
                path: props.festiveModeOn ? DataLayerEventPath.FESTIVE : DataLayerEventPath.STANDARD,
              })
            }).catch(() => {
              //
            })
          })
          .catch((error) => {
            setSuccess(false);
            console.error('Failed to notify:', error);
          });
      }
    } catch (error) {
      console.error('Failed to notify:', error);
      setSuccess(false);
    }
  };

  const handleChange = (e: any) => {
    e.stopPropagation();
    setValue(e.target.value);
    setEmailError(false);
  }

  const content = (
    <form onSubmit={handleSubmit} noValidate>
      <DialogTitle component="h1" sx={{ fontSize: isTablet ? 17 : 40, fontWeight: isArabicLanguage ? 700 : 500, lineHeight: 1.35, padding: isTablet ? '1.5rem 0.5rem 1rem 0.5rem' : '36px 16px 0 16px' }}>
        {
          success === true ? t('notify_thank_you_title') :
            success === false ? t('notify_error_title') :
              t('notify_title')
        }
      </DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        className="close-modal-icon"
        sx={{
          position: 'absolute',
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <img src={closeIcon} alt=""/>
      </IconButton>
      <DialogContent sx={{ padding: isTablet ? 1 : '32px 16px 32px 16px' }}>
        <Typography variant="body1" sx={{ mb: isTablet ? 2 : 4, fontWeight: 300, color: "#4A525D", fontSize: isTablet ? 14 : 16 }}>
          {
            success === true ? t('notify_thank_you_description') :
              success === false ? t('notify_error_description') :
                t('notify_description', { hasNewLine: isTablet ? '\n' : '' })
          }
        </Typography>
        {success === undefined && (
          <>
            <TextField
              required
              type="email"
              name={t('email')}
              fullWidth
              variant="standard"
              value={value}
              onChange={handleChange}
              placeholder={t('email')}
              error={emailError}
              helperText={emailError && t('invalid_email')}
              sx={{
                '& .Mui-error, .MuiFormHelperText-root.Mui-error': {
                  color: '#FF453A',
                },
                '& .MuiInputBase-input:focus':  {
                  outline: 'none!important',
                }
              }}
            />
            <Typography variant="body1" sx={{ mt: 2, fontWeight: 400, fontSize: 17 }}>
              {t('notify_email_description')}
            </Typography>
          </>
        )}
      </DialogContent>
      <DialogActions sx={{ padding: isTablet ? 1 : undefined, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        <Box width="100%" display="flex" justifyContent="center">
          {success !== undefined ? (
            <Button
              type="button"
              onClick={handleClose}
            >
              {t('close')}
            </Button>
          ) : (
            <Button
              type="submit"
              disabled={!value || emailError}
            >
              {t('notify_me')}
            </Button>
          )}
        </Box>
        <div className={styles.reCaptchaLegalNotice}>
          {t('site_recapthca_protected')}
           &nbsp;<a href="https://policies.google.com/privacy" target="_blank">{t('site_recapthca_pps')}</a> {t('site_recaptcha_and')}
           &nbsp;<a href="https://policies.google.com/terms" target="_blank">{t('site_recaptcha_tss')}</a> {t('site_recaptcha_apply')}
        </div>
      </DialogActions>
    </form>
  )

  return isTablet ? (
    <SwipeableDrawer
      disableSwipeToOpen
      anchor="bottom"
      open={open}
      onOpen={f=>f}
      onClose={handleClose}
      className={styles.dialog}
      sx={{
        // blurring the background when the dialog is open
        backdropFilter: open ? 'blur(5px)' : 'none',
      }}
    >
      {content}
    </SwipeableDrawer>
  ) : (
    <Dialog maxWidth="sm" open={open} onClose={handleClose} sx={{
      '& .MuiDialog-paper': {
        borderRadius: "12px",
        padding: 2,
        width: 907,
        maxWidth: '100%',
        margin: 0
      }
    }}>
      {content}
    </Dialog>
  );
}

export default NotifyModal;
