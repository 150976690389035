import React, { useState, useEffect, useMemo } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import clsx from 'clsx';
import styles from './styles.module.scss';
import logo from '../../assets/icons/logo.svg';
import arrowLeft from '../../assets/icons/arrow-left.svg';
import ChevronDown from '../../assets/tsx-icons/Chevron-down';
import i18n from 'i18next';
import { useMediaQuery } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { useCommon } from '../../state/common';
import { loadAPIVideos } from '../../helpers/utils';

const Header = ({
  festiveModeOn
                }: {
    festiveModeOn?: boolean
}) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const isTablet = useMediaQuery('(max-width:768px)');
    const { isCameraOn, setIsCamerOn } = useCommon();
    const [lang, setLang] = useState(
        localStorage.getItem('user-selected-language') || 'en'
    );
    const [isOpen, setIsOpen] = useState(false);
    const pathNamesForBackArrow = useMemo(() => {
        return ['/selectyourscene'];
    }, []);

    useEffect(() => {
        lang === 'ar'
            ? (document.body.dir = 'rtl')
            : (document.body.dir = 'ltr');
    }, [lang]);

    const handleChange = async (value: string) => {
        setIsOpen(false);
        await loadAPIVideos(value, t, { festiveModeOn: festiveModeOn && value === 'en' });
        await new Promise((resolve) => setTimeout(resolve, 100)); // wait for dropdown to close
        // not waiting causes a flash of the dropdown menu when changing language
        setLang(value);
        i18n.changeLanguage(value);
        localStorage.setItem('user-selected-language', value);
        navigate('/');
    };

    interface LanguageLabels {
        [key: string]: string;
    }

    const LANGUAGE_LABELS: LanguageLabels = {
        ar: 'عربي',
        en: 'English',
        'zh-CN': '中文',
        // de: 'Deutsch',
        fr: 'Français',
        it: 'Italiano',
        es: 'Español',
        'pt-BR': 'Português',
    };

    const handleBackBtn = () => {
        switch (location.pathname.split('/')[1]) {
            case 'create':
                return isCameraOn
                    ? setIsCamerOn(false)
                    : navigate('/selectyourscene');
            case 'termsandconditions':
            case 'privacypolicy':
                return navigate(location.state?.from || '/', {
                    state: location.state?.prevState,
                });
            default:
                return navigate('/');
        }
    };

    if (location.pathname.split('/')[1] === 'create' && isTablet) {
        return null;
    }

    return (
        <header className={styles.header}>
            <div className={clsx(styles.wrap, 'wrap')}>
                <div className={styles.col}>
                    {pathNamesForBackArrow.includes(location.pathname) && (
                        <button
                            type="button"
                            id="go-back-button"
                            className={styles.backbtn}
                            onClick={() => handleBackBtn()}
                            title={t('title_go_back')}
                        >
                            <img src={arrowLeft} alt="" />
                        </button>
                    )}
                </div>
                <Link
                    id="home-page-button"
                    to=""
                    className={styles.logo}
                    title={t('title_qatar')}
                >
                    <img src={logo} alt="" />
                </Link>
                <div className={styles.col}>
                    <ClickAwayListener onClickAway={() => setIsOpen(false)}>
                        <div
                            className={clsx(styles.dropdown, {
                                [styles.dropdownOpened]: isOpen,
                            })}
                        >
                            <button
                                className={styles.select}
                                type="button"
                                id="select-language-button"
                                aria-label={t('title_language_selector')}
                                data-bs-toggle="dropdown"
                                onClick={() => setIsOpen((prev) => !prev)}
                            >
                                {LANGUAGE_LABELS[lang || 'en']}
                                <ChevronDown />
                            </button>
                            <ul className={styles.selectMenu}>
                                <li>
                                    <button
                                        type="button"
                                        id="english-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'en',
                                            },
                                            styles.ltrContent
                                        )}
                                        onClick={() => handleChange('en')}
                                    >
                                        English
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        id="arabic-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'ar',
                                            },
                                            styles.rtlContent
                                        )}
                                        onClick={() => handleChange('ar')}
                                    >
                                        عربي
                                    </button>
                                </li>
                                <li>
                                    <button
                                        type="button"
                                        id="chinese-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'zh-CN',
                                            },
                                            styles.ltrContent
                                        )}
                                        onClick={() => handleChange('zh-CN')}
                                    >
                                        中文
                                    </button>
                                </li>
                                {/*<li>*/}
                                {/*    <button*/}
                                {/*        id="german-language-button"*/}
                                {/*        className={clsx(*/}
                                {/*            {*/}
                                {/*                [styles.itemSelected]:*/}
                                {/*                    lang === 'de',*/}
                                {/*            },*/}
                                {/*            styles.ltrContent*/}
                                {/*        )}*/}
                                {/*        onClick={() => handleChange('de')}*/}
                                {/*    >*/}
                                {/*        Deutsch*/}
                                {/*    </button>*/}
                                {/*</li>*/}
                                <li>
                                    <button
                                      type="button"
                                      id="french-language-button"
                                      className={clsx(
                                        {
                                            [styles.itemSelected]:
                                            lang === 'fr',
                                        },
                                        styles.ltrContent
                                      )}
                                      onClick={() => handleChange('fr')}
                                    >
                                        Français
                                    </button>
                                </li>
                                <li>
                                    <button
                                        id="spanish-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'es',
                                            },
                                            styles.ltrContent
                                        )}
                                        onClick={() => handleChange('es')}
                                    >
                                        Español
                                    </button>
                                </li>
                                <li>
                                    <button
                                        id="italian-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'it',
                                            },
                                            styles.ltrContent
                                        )}
                                        onClick={() => handleChange('it')}
                                    >
                                        Italiano
                                    </button>
                                </li>
                                <li>
                                    <button
                                        id="portuguese-language-button"
                                        className={clsx(
                                            {
                                                [styles.itemSelected]:
                                                    lang === 'pt-BR',
                                            },
                                            styles.ltrContent
                                        )}
                                        onClick={() => handleChange('pt-BR')}
                                    >
                                      Português
                                    </button>
                                </li>
                            </ul>
                        </div>
                    </ClickAwayListener>
                </div>
            </div>
        </header>
    );
};
export default Header;
