import React, { useMemo } from 'react'
import styles from './styles.module.scss';
import clsx from 'clsx'
import candyIcon from '../../../assets/icons/candy-on.svg'
import candyOffIcon from '../../../assets/icons/candy-off.svg'
import { useTranslation } from 'react-i18next'

const FestiveToggleButton = ({
  canUseFestiveMode,
  festiveModeOn,
  setFestiveModeOn,
                                            }: {
  festiveModeOn: boolean;
  setFestiveModeOn: (value: boolean) => void;
  canUseFestiveMode: boolean;
}) => {
  const { t } = useTranslation();

  const isFestive = useMemo(() => {
    return festiveModeOn && canUseFestiveMode;
  }, [festiveModeOn, canUseFestiveMode]);

  return (
    <button
      className={clsx(styles.toggleTrack, {
        [styles.toggleTrackActive]: isFestive,
      })}
      onClick={(e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        e.stopPropagation();
        setFestiveModeOn(!isFestive)}
      }
    >
      <p>{isFestive ? t('festive_on') : t('festive_off')}</p>
      <div className={clsx(styles.toggleKnob, {
        [styles.toggleKnobActive]: isFestive,
      })}>
        <img src={isFestive ? candyIcon : candyOffIcon} alt="X-Mas"/>
      </div>
    </button>
  )
}

export default FestiveToggleButton;
