import { FaceSwapAPI } from '../bdreams/FaceSwapAPI';
import { Language } from '../bdreams/FaceSwap';

const utils = {
    sceneGender: [
        { female: true, male: false }, // 1
        { female: true, male: true }, // 2
        { female: true, male: true }, // 3
        { female: true, male: false }, // 4
        { female: true, male: true }, // 5
        { female: true, male: false }, // 6
        { female: false, male: true }, // 7
        { female: true, male: false }, // 8
        { female: true, male: true }, // 9
        { female: true, male: true }, // 10
    ],
    sceneThumbnail: [9, 7, 1, 0.01, 7, 3, 6, 3.8, 2.9, 10.6],
    youtubeEmbeds: {
        en: 'https://www.youtube.com/embed/qTeLp1McNRU',
        ar: 'https://www.youtube.com/embed/qTeLp1McNRU',
        de: 'https://www.youtube.com/embed/qTeLp1McNRU',
        fr: 'https://www.youtube.com/embed/qTeLp1McNRU',
        es: 'https://www.youtube.com/embed/qTeLp1McNRU',
        it: 'https://www.youtube.com/embed/qTeLp1McNRU',
        'pt-BR': 'https://www.youtube.com/embed/qTeLp1McNRU',
        'zh-CN': `${
            process.env.NODE_ENV === 'development'
                ? 'https://qatartest-web-b6dmcmcrdzh7hahm.z01.azurefd.net'
                : ''
        }/content/hero/en.mp4#t=0.01`,
    },
    defaultYoutubeEmbed: 'https://www.youtube.com/embed/qTeLp1McNRU',
    skinTones: [
        '#F8F7E9',
        '#EDC9B0',
        '#AD9675',
        '#826943',
        '#5D3C0A',
        '#421A09',
    ],
};

export function getSceneGenderTag(
    sceneIndex: number,
    translatedMale: string,
    translatedFemale: string
): string {
    const sceneGenderElement = utils.sceneGender[sceneIndex];
    if (!sceneGenderElement) return '';
    const count = Object.keys(sceneGenderElement).filter(
        (key) => sceneGenderElement[key as keyof typeof sceneGenderElement]
    ).length;
    if (count === 1) {
        return sceneGenderElement.female
            ? translatedFemale
            : sceneGenderElement.male
            ? translatedMale
            : '';
    }
    return `${translatedMale}/${translatedFemale}`;
}

export function getSceneThumbnailTimestamp(sceneIndex: number): number {
    return utils.sceneThumbnail[sceneIndex];
}

export type LoadApiVideosParams = {
    festiveModeOn?: boolean;
}

export async function loadAPIVideos(language: string, t: any, options?: LoadApiVideosParams) {
    const { festiveModeOn = false } = options || {};
    const campaignId = festiveModeOn ? '2E2A226D-6D94-4C5E-AF47-93F0B695E6D9'
      : 'BDF7D823-4CE1-4591-9E30-F4283099B71C';
    try {
        await FaceSwapAPI.load(
            campaignId,
            language as Language
        );
    } catch (error) {
        //alert(t('alert_error') || error);
        window.location.reload();
        console.log(error);
    }
}

export function getYoutubeLink(): string {
    const language = localStorage.getItem('user-selected-language') || 'en';
    return (
        utils.youtubeEmbeds[language as keyof typeof utils.youtubeEmbeds] ||
        utils.defaultYoutubeEmbed
    );
}

export default utils;
